#features {
  height: 80vh;
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 2fr;
}
.feature_header {
  display: grid;
  grid-template-columns: 1fr 1fr 3fr;
}
.btn_features {
  width: max-content;
  display: inline-block;
  color: var(--color-white);
  padding: 0.5rem;
  margin: 0.3rem;
  border-radius: 2rem;
  cursor: pointer;
  border: 1px solid var(--color-white);
  background-color: transparent;
  transition: var(--transition);
}
.btn_features:hover {
  background: var(--color-white);
  color: var(--color-bg);
  border-color: transparent;
}
.btn_features.clicked {
  background-color: var(--color-white);
  color: var(--color-bg);
}

.feature_header h6 {
  font-size: 14px;
  font-family: "Norwester", sans-serif;
  line-height: 16.8px;
  font-weight: 400;
  margin-left: 1rem;
}
.feature_title {
  font-size: 60px;

  line-height: 68.64px;
  font-weight: 450;
  width: 27rem;
  text-align: left;

  z-index: 1;
}
.feature_text {
  font-size: 16px;
  width: 40rem;

  z-index: 1;
}
.feature_number {
  font-size: 24px;
  line-height: 27.46px;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}
span {
  font-size: 14px;
  line-height: 16.02px;
  margin-left: 0.5rem;
}
.span_number {
  font-family: "Norwester", sans-serif;
  font-size: 12px;
  line-height: 14.4px;
}
.layers2 {
  position: absolute;
  width: 40%;

  top: 11rem;
  left: -2rem;
  z-index: -1;
}

.feature_container {
  position: absolute;
  left: 18rem;
  border: 1px solid transparent;
  border-radius: 1rem;
  padding: 0.1rem 14rem;
  text-align: left;
  transition: var(--transition);
  z-index: 1;
}

/*==========Media Queries (MEDIUM DEVICES)==============*/
@media screen and (max-width: 1024px) {
  #features {
    height: 50vh;
  }

  .feature_container {
    position: absolute;
    left: 10rem;

    padding: 0.1rem 1rem;
    margin-top: -1.3rem;
  }
  .text-container {
    position: absolute;
    left: 1.5rem;

    width: 45rem;

    border: 1px solid transparent;
    border-radius: 1rem;

    text-align: left;
    transition: var(--transition);
  }
}

/*==========Media Queries (SMALL DEVICES)==============*/
@media screen and (max-width: 600px) {
  #features {
    height: 52vh;
  }

  .feature_header {
    display: grid;
    grid-template-columns: 1fr 1fr 4fr;
    margin-bottom: 2rem;

    top: 10px;
    position: relative;
  }
  .feature_container {
    position: absolute;
    left: -0.5rem;
    margin-top: 0.1rem;
  }
  .feature_header h6 {
    font-size: 12px;
    line-height: 14.4px;
  }
  .text-container {
    width: 23rem;
  }
  .layers2 {
    width: 25%;
    margin-left: 2rem;
    margin-top: -5rem;
    opacity: 1;
    z-index: 1;
  }

  .text-container h4 {
    position: relative;
    top: 15px;
  }

  .text-container p {
    position: relative;
    top: 15px;
  }

  .feature_number {
    font-size: 16px;
  }
  .feature_title {
    font-size: 30px;
    line-height: 30px;
    width: 20rem;
  }
  .feature_text {
    font-size: 14px;
    margin-top: 0.5rem;
    width: 19rem;
  }
  .button-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  @keyframes scrollButtons {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-100% - 10px));
    }
  }

  .button-container::-webkit-scrollbar {
    display: none;
  }
  .button-container {
    display: flex;
    width: 100%;
    overflow-x: scroll;
    white-space: nowrap;
    animation: scrollButtons 20s linear infinite;
    position: relative;
  }
  .btn_features.hide-on-mobile {
    display: none;
    overflow-x: hidden;
    margin-left: -50px;
    visibility: hidden;
  }
  .btn_features.hide-on-mobile {
    transform: translateX(-100%);
    visibility: hidden;
  }
  .feature_section {
    position: relative;
    overflow: hidden;
    width: 100vw;
  }
}
