@import url("https://fonts.cdnfonts.com/css/norwester");

@import url("https://p.typekit.net/p.css?s=1&k=ztw0vem&ht=tk&f=39496&a=93766379&app=typekit&e=css");
@font-face {
    font-family:"neue-haas-grotesk-display";
    src:url("https://use.typekit.net/af/1ba16c/00000000000000007735bb5a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/1ba16c/00000000000000007735bb5a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/1ba16c/00000000000000007735bb5a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:500;font-stretch:normal;
    }
    
    .tk-neue-haas-grotesk-display { font-family: "neue-haas-grotesk-display",sans-serif; }
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --color-bg: #011022;

  --color-white: #ffffff;

  --transition: all 400ms ease;

  --container-width-lg: 96%;
  --container-width-md: 95%;
  --container-width-sm: 98%;

}

html {
  scroll-behavior: smooth;

}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: "neue-haas-grotesk-display";
  background: var(--color-bg);
  color: var(--color-white);
}

.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

h1 {
  font-size: 24px;
  font-weight: 400;
  height: 28.8px;
}


section {
  margin-top: 10rem;
}



a {
  color: var(--color-white);
  transition: var(--transition);
}

a:hover {
  color: var(--color-white);
}

.btn {
  width: max-content;
  display: inline-block;
  color: var(--color-white);
  padding: 0.01rem 1.9rem 1rem 1.9rem;
  border-radius: 2rem;
  cursor: pointer;
  border: 0.5px solid var(--color-white);
  transition: var(--transition);
}

.btn:hover {
  background: var(--color-white);
  color: var(--color-bg);
  border-color: transparent;
}

.btn-primary {
  width: max-content;
  display: inline-block;
  color: var(--color-white);
  font-family: "Norwester";
  font-size: 14px;
  line-height: 16.8px;
}

img {
  width: 100%;
  
}

/*==========Media Queries (MEDIUM DEVICES)==============*/
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  
  }
  

  
}

/*==========Media Queries (SMALL DEVICES)==============*/
@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);

  }
  .btn-primary {
    
    font-size: 12px;
    line-height: 14.4px;
  }
}
